import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import { useQuery } from '../../hooks/UseQuery';
import { getDataFromSessionStorage, setDataToSessionStorage } from '../../utils/Common';
import { getRequest, postRequest } from '../../utils/apiMethods';
import SuccessCard from '../../components/SuccessCard';

export default function AdminLogin() {
    const [isLoader, setIsLoader] = useState(false);
    const [showSuccessAfterLogin, setShowSuccessAfterLogin] = useState(false)
    const query = useQuery();

    useEffect(() => {
        const requestTokenParams = query.get('request_token');
        if (!requestTokenParams) {
            const url = `https://kite.zerodha.com/connect/login?v=3&api_key=${process.env.REACT_APP_API_KEY}`
            window.location.assign(
                url
            );
        } else if (requestTokenParams) {
            setIsLoader(true)
            callAccessTokenApi(requestTokenParams)
        }
    }, [])

    const callAccessTokenApi = async (requestTokenParams) => {
        const response = await getRequest(`${process.env.REACT_APP_ACCESS_URL}?request_token=${requestTokenParams}`);
        if (response.message) {
            setIsLoader(false)
            setShowSuccessAfterLogin(true);
        } else {
            setIsLoader(false)
            setShowSuccessAfterLogin(false)
        }
    }

    const callRawData = async () => {
        const response = await getRequest(`${process.env.REACT_APP_RAW_DATA}`);
        if (response.message) {
            setIsLoader(false)
            setShowSuccessAfterLogin(true);
        } else {
            setIsLoader(false)
            setShowSuccessAfterLogin(false)
        }
    }

    return (
        <>
            {isLoader ? (<Loader />) : null}
            {showSuccessAfterLogin ? (
                <>
                    <SuccessCard />
                </>
            ) : null}
        </>
    )
}
